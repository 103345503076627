<template>
    <div class="grid crud-demo">
        <Loader v-model="loading" />
        <Helper
            :expandable="true"
            v-model="customerHelper"
            header="Buscar Cliente"
            :style="{ width: '85%' }"
            :headers="customersHeaders"
            :headersExpandable="shippingsHeaders"
            :rows="customers"
            rowsExpandable="shippings"
            @selected="selectCustomer"
            @selected-detail="selectShipping"
        />
        <Toast />
        <div class="col-12">
            <div class="card">
                <Panel header="Terminal Virtual">
                    <Fieldset :toggleable="true" legend="Datos de Facturacion">
                        <BasicFormToolbar :actions="['save']" @save="saveCustomer"> </BasicFormToolbar>
                        <div class="p-fluid formgrid grid">
                            <FormInputText :readonly="true" wrapperClass="field col-12 xl:col-4" label="Nombre" v-model="paymentParameters.orderInformationBillTo.name" :valid="customerValidate.validations.name" />
                            <FormInputText
                            idss="prueba"
                                :readonly="true"
                                wrapperClass="field col-12 md:col-6 xl:col-4"
                                label="E-mail"
                                v-model="paymentParameters.orderInformationBillTo.email"
                                :valid="customerValidate.validations.buyerInformation_email"
                                validation="Favor de llenar el campo con formato de correo '***@****.****'"
                            />
                            <FormInputText :readonly="true" wrapperClass="field col-12 md:col-6 xl:col-4" label="Telefono" v-model="paymentParameters.orderInformationBillTo.phoneNumber" />
                            <Panel header="Direccion">
                                                                <BasicFormToolbar :actions="['new', 'delete']" @new="newShipping" @delete="deleteShipping" />
                                <div class="p-fluid formgrid grid">
                                    <FormInputText wrapperClass="field col-12 md:col-6 xl:col-5" label="Nombre" v-model="paymentParameters.orderInformationBillTo.firstName" :valid="shippingValidate.validations.shipTo_firstName" />
                                    <FormInputText wrapperClass="field col-12 md:col-6 xl:col-6" label="Apellido" v-model="paymentParameters.orderInformationBillTo.lastName" :valid="shippingValidate.validations.shipTo_lastName" />
                                    <FormInputSwitch :wrapperClass="'field col-1'" label="Default" v-model="paymentParameters.orderInformationBillTo.is_default" />
                                    <FormInputText wrapperClass="field col-12 xl:col-5" label="Direccion #1" v-model="paymentParameters.orderInformationBillTo.address1" :valid="shippingValidate.validations.shipTo_address1" />
                                    <FormInputText wrapperClass="field col-12 xl:col-5" label="Direccion #2" v-model="paymentParameters.orderInformationBillTo.address2" />
                                    <FormInputText wrapperClass="field col-12 md:col-3 xl:col-2" label="Codigo Postal" v-model="paymentParameters.orderInformationBillTo.postalCode" />
                                    <Country wrapperClass="field col-12 md:col-9 xl:col-4" label="Pais" v-model="paymentParameters.orderInformationBillTo.country" :valid="shippingValidate.validations.shipTo_country" />
                                    <FormInputText wrapperClass="field col-12 md:col-6 xl:col-4" label="Estado" v-model="paymentParameters.orderInformationBillTo.district" />
                                    <FormInputText wrapperClass="field col-12 md:col-6 xl:col-4" label="Ciudad" v-model="paymentParameters.orderInformationBillTo.locality" />
                                </div>
                            </Panel>
                        </div>
                    </Fieldset>
                    <br />
                    <FadeInOut entry="left" exit="right" :duration="350" mode="out-in">
                        <CardTokenization
                            @deleted-payment="deletedPaymentInstrument"
                            @created="createdPaymentInstrument"
                            @is-new-default-payment="isNewDefaultPaymentInstrument"
                            ref="card"
                            v-show="customer.id"
                            :paymentInstruments="customer.paymentInstruments"
                            :customer="customer"
                            v-model="paymentParameters"
                        />
                    </FadeInOut>
                </Panel>
            </div>
        </div>
    </div>
</template>

<script>
import { PaymentParameters } from '../../../models/custom/business_wallet/PaymentParameters';
import { BWCustomer } from '../../../models/custom/business_wallet/BWCustomer';
import { BWShippingAddress } from '../../../models/custom/business_wallet/BWShippingAddress';
import { BWPaymentInstrument } from '../../../models/custom/business_wallet/BWPaymentInstrument';
import { BWCybersourceInstallmentConfiguration } from '../../../models/custom/business_wallet/BWCybersourceInstallmentConfiguration';
import BasicFormToolbar from '../../../components/general/BasicFormToolbar.vue';
import FormInputText from '../../../components/general/FormInputText.vue';
import CardTokenization from '../BusinessWallet/Components/CardTokenization.vue';
// import axios from "axios";
import Country from '../../../components/basic data/Country.vue';

import { FilterMatchMode } from 'primevue/api';
import { Rule, fillObject, ErrorToast, satCatalogo, Toast, HeaderGrid, countries, validate } from '../../../utilities/General';
import Loader from '../../../components/general/Loader.vue';
import Helper from '../../../components/general/HelperDialog.vue';
import { PAYMENT_DICTIONARY } from '../../../utilities/BW_UTILITIES';
import { ERPUser } from '../../../models/seguridad/ERPUser';
import moment from 'moment';


var math = require('mathjs');

export default {
    props: {
        id: null,
        view: null,
    },
    data() {
        return {
            usuario: new ERPUser(),
            session: {
                customer:null,
                company: 0,
                branch: 0
            },
            sucursales: [],
            installment_config: null,
            countries: [],
            customerHelper: {
                visible: false,
            },
            paymentInstrumentHelper: {
                visible: false,
            },
            customers: [],
            customersHeaders: [new HeaderGrid('Nombre', 'name'), new HeaderGrid('Email', 'buyerInformation_email'), new HeaderGrid('Telefono', 'phone')],
            paymentInstrumentHeaders: [
                new HeaderGrid('Tipo', 'type'),
                new HeaderGrid('Sub Tipo', 'card_type_text'),
                new HeaderGrid('Tarjeta', 'card_number'),
                new HeaderGrid('Mes', 'card_expirationMonth'),
                new HeaderGrid('Año', 'card_expirationYear'),
                new HeaderGrid('Predeterminada', 'is_default', { type: 'boolean' }),
            ],
            shippingsHeaders: [
                new HeaderGrid('Nombre', 'shipTo_firstName'),
                new HeaderGrid('Apellido', 'shipTo_lastName'),
                new HeaderGrid('Direccion', 'shipTo_address1'),
                new HeaderGrid('Direccion 2', 'shipTo_address2'),
                new HeaderGrid('Pais', 'shipTo_country'),
                new HeaderGrid('Localidad', 'shipTo_locality'),
            ],
            paymentParameters: new PaymentParameters(),
            customer: new BWCustomer(),
            shippingAddress: new BWShippingAddress(),
            paymentInstrument: new BWPaymentInstrument(),
            filters: {},
            customerRules: [new Rule({ name: 'name' }), new Rule({ name: 'buyerInformation_email', type: 'email' })],
            shippingRules: [new Rule({ name: 'shipTo_firstName' }), new Rule({ name: 'shipTo_lastName' }), new Rule({ name: 'shipTo_address1' }), new Rule({ name: 'shipTo_country' })],
            paymentInstrumentRules: [new Rule({ name: 'card_type_id' }), new Rule({ name: 'card_expirationYear' }), new Rule({ name: 'card_expirationMonth' }), new Rule({ name: 'card_number' }), new Rule({ name: 'card_cvv' })],
            paymentInstrumentBillToRules: [new Rule({ name: 'billTo_firstName' }), new Rule({ name: 'billTo_lastName' }), new Rule({ name: 'billTo_address1' }), new Rule({ name: 'billTo_country' })],
            customerValidate: {
                valid: false,
                validations: {
                    name: null,
                    buyerInformation_email: null,
                },
            },
            shippingValidate: {
                valid: false,
                validations: {
                    shipTo_firstName: null,
                    shipTo_lastName: null,
                    shipTo_address1: null,
                    shipTo_country: null,
                },
            },
            paymentInstrumentValidate: {
                valid: false,
                validations: {
                    card_type_id: null,
                    card_expirationYear: null,
                    card_expirationMonth: null,
                    card_number: null,
                    card_cvv: null,
                },
            },
            paymentInstrumentBillToValidate: {
                valid: false,
                validations: {
                    billTo_firstName: null,
                    billTo_lastName: null,
                    billTo_address1: null,
                    billTo_country: null,
                },
            },
            loading: false,
            loadingInputs: false,
            c_Moneda: [],
            cards: [
                { id: '001', text: 'Visa' },
                { id: '002', text: 'Mastercard' },
                { id: '003', text: 'AmericanExpress' },
            ],
        };
    },
    components: { Loader, Helper, BasicFormToolbar, FormInputText, CardTokenization, Country },
 
   async created() {
       

        const params = window.location.search;
        const urlParams = new URLSearchParams(params);

        this.session.company = urlParams.get('company');
        this.session.branch = urlParams.get('branch');
        this.session.customer =  urlParams.get('id');

    },

    watch: {
        async ['entity.id_tm_customer'](newValue) {
            if (newValue) {
                try {
                    this.loadingInputs = true;
                    this.projectsByCustomer = this.projects.filter((project) => project.id_tm_customer == newValue);
                    if (!this.projectsByCustomer.find((project) => project.id == this.entity.id_tm_project)) {
                        this.entity.id_tm_project = null;
                    }
                } catch (error) {
                    this.$toast.add(new ErrorToast(error));
                } finally {
                    this.loadingInputs = false;
                }
            }
        },
        async ['paymentParameters.paymentInformation.card.number'](newValue) {
            console.log(newValue);
            if (newValue) {
                if (newValue.length > 0) {
                    let firstNumber = newValue.substring(0, 1);
                    if (firstNumber == '3')
                        this.paymentParameters.paymentInformation.card.type = {
                            id: '003',
                            text: 'AmericanExpress',
                        };
                    else if (firstNumber == '4')
                        this.paymentParameters.paymentInformation.card.type = {
                            id: '001',
                            text: 'Visa',
                        };
                    else if (firstNumber == '5')
                        this.paymentParameters.paymentInformation.card.type = {
                            id: '002',
                            text: 'Mastercard',
                        };
                } else this.paymentParameters.paymentInformation.card.type = null;
            } else this.paymentParameters.paymentInformation.card.type = null;
        },
    },
    computed: {
        msi() {
            let config = [];
            if (this.installment_config.three_months) config.push({ value: '03', text: '3 meses' });
            if (this.installment_config.six_months) config.push({ value: '06', text: '6 meses' });
            if (this.installment_config.nine_months) config.push({ value: '09', text: '9 meses' });
            if (this.installment_config.twelve_months) config.push({ value: '12', text: '12 meses' });
            if (this.installment_config.eighteen_months) config.push({ value: '18', text: '18 meses' });
            if (this.installment_config.twentyfour_months) config.push({ value: '24', text: '24 meses' });

            return config;
        },
        partialAmount() {
            if (!this.paymentParameters.orderInformationAmountDetails.totalAmount) {
                return 0;
            } else if (this.paymentParameters.orderInformationAmountDetails.totalAmount == 0 || !this.paymentParameters.installmentInformation.totalCount) {
                return 0;
            } else {
                var plan = parseInt(this.paymentParameters.installmentInformation.totalCount);
                return math.chain(this.paymentParameters.orderInformationAmountDetails.totalAmount).divide(plan).done();
            }
        },
    },

    
             

   
    async mounted() {
        try {
            this.loading = true;
            this.c_Moneda = await satCatalogo(9);
            this.countries = await countries();
            
                  
        } catch (error) {
            console.log(error);
            this.loading = false;
        }

        await this.refresh();
    },

    methods: {
        hideDialog() {
            this.newDialog = false;
        },

        async savePaymentInstrument() {
            try {
                let defaults = this.customers.find((x) => x.id == this.customer.id).paymentInstruments.filter((x) => x.is_default == true);
                if (defaults.length == 0) this.paymentParameters.paymentInformation.paymentType.is_default = true;
                if (this.paymentInstrument.id) throw 'No se puede editar un instrumento de pago, favor de eliminarlo y crear otro';
                this.loading = true;
                let date = moment(this.paymentParameters.paymentInformation.card.expiration).format('DD/MM/YYYY');
                this.paymentInstrument.type = this.paymentParameters.paymentInformation.paymentType.type;
                this.paymentInstrument.is_default = this.paymentParameters.paymentInformation.paymentType.is_default;

                if (!this.customer.id) throw 'No existe un cliente para este tipo de pago, favor de selecciona uno o crearlo';
                this.paymentInstrument.id_bw_cybersource_customer = this.customer.id;
                this.paymentInstrument.useAs = this.paymentParameters.paymentInformation.card.useAs ?? 'credit';
                this.paymentInstrument.is_default = this.paymentParameters.paymentInformation.paymentType.is_default;
                this.paymentInstrument.card_expirationMonth = this.paymentParameters.paymentInformation.card.expiration ? date.split('/')[1] : null;
                this.paymentInstrument.card_expirationYear = this.paymentParameters.paymentInformation.card.expiration ? date.split('/')[2] : null;
                this.paymentInstrument.card_number = this.paymentParameters.paymentInformation.card.number;
                this.paymentInstrument.card_cvv = this.paymentParameters.paymentInformation.card.securityCode;
                this.paymentInstrument.card_type_id = this.paymentParameters.paymentInformation.card.type ? this.paymentParameters.paymentInformation.card.type.id : null;
                this.paymentInstrument.card_type_text = this.paymentParameters.paymentInformation.card.type ? this.paymentParameters.paymentInformation.card.type.text : null;
                this.paymentInstrument.billTo_firstName = this.paymentParameters.paymentInformation.paymentType.is_using_shipping
                    ? this.paymentParameters.orderInformationBillTo.firstName
                    : this.paymentParameters.orderInformationBillToExtra.firstName;
                this.paymentInstrument.billTo_email = this.paymentParameters.paymentInformation.paymentType.is_using_shipping ? this.paymentParameters.orderInformationBillTo.email : this.paymentParameters.orderInformationBillToExtra.email;
                this.paymentInstrument.billTo_phoneNumber = this.paymentParameters.paymentInformation.paymentType.is_using_shipping
                    ? this.paymentParameters.orderInformationBillTo.phoneNumber
                    : this.paymentParameters.orderInformationBillToExtra.phoneNumber;
                this.paymentInstrument.billTo_lastName = this.paymentParameters.paymentInformation.paymentType.is_using_shipping ? this.paymentParameters.orderInformationBillTo.lastName : this.paymentParameters.orderInformationBillToExtra.lastName;
                this.paymentInstrument.billTo_address1 = this.paymentParameters.paymentInformation.paymentType.is_using_shipping ? this.paymentParameters.orderInformationBillTo.address1 : this.paymentParameters.orderInformationBillToExtra.address1;
                this.paymentInstrument.billTo_address2 = this.paymentParameters.paymentInformation.paymentType.is_using_shipping ? this.paymentParameters.orderInformationBillTo.address2 : this.paymentParameters.orderInformationBillToExtra.address2;
                this.paymentInstrument.billTo_locality = this.paymentParameters.paymentInformation.paymentType.is_using_shipping ? this.paymentParameters.orderInformationBillTo.locality : this.paymentParameters.orderInformationBillToExtra.locality;
                this.paymentInstrument.billTo_postalCode = this.paymentParameters.paymentInformation.paymentType.is_using_shipping
                    ? this.paymentParameters.orderInformationBillTo.postalCode
                    : this.paymentParameters.orderInformationBillToExtra.postalCode;
                this.paymentInstrument.billTo_administrativeArea = this.paymentParameters.paymentInformation.paymentType.is_using_shipping
                    ? this.paymentParameters.orderInformationBillTo.district
                    : this.paymentParameters.orderInformationBillToExtra.district;
                this.paymentInstrument.billTo_country = this.paymentParameters.paymentInformation.paymentType.is_using_shipping
                    ? this.paymentParameters.orderInformationBillTo.country
                        ? this.paymentParameters.orderInformationBillTo.country.code
                        : null
                    : this.paymentParameters.orderInformationBillToExtra.country
                    ? this.paymentParameters.orderInformationBillToExtra.country.code
                    : null;
                this.paymentInstrumentValidate = validate(this.paymentInstrument, this.paymentInstrumentRules);
                if (!this.paymentInstrumentValidate.valid) throw 'Favor de llenar los campos';
                this.paymentInstrumentBillToValidate = validate(this.paymentInstrument, this.paymentInstrumentBillToRules);
                if (this.paymentParameters.paymentInformation.paymentType.is_using_shipping) {
                    //* Validamos el Shipping
                    if (!this.paymentInstrumentBillToValidate.valid) {
                        this.shippingValidate.validations.shipTo_firstName = this.paymentInstrumentBillToValidate.validations.billTo_firstName;
                        this.shippingValidate.validations.shipTo_lastName = this.paymentInstrumentBillToValidate.validations.billTo_lastName;
                        this.shippingValidate.validations.shipTo_address1 = this.paymentInstrumentBillToValidate.validations.billTo_address1;
                        this.shippingValidate.validations.shipTo_country = this.paymentInstrumentBillToValidate.validations.billTo_country;
                        throw 'Favor de llenar los campos';
                    }
                } else {
                    //* Validamos la direcion de la tarjeta
                    this.shippingValidate.validations.shipTo_firstName = true;
                    this.shippingValidate.validations.shipTo_lastName = true;
                    this.shippingValidate.validations.shipTo_address1 = true;
                    this.shippingValidate.validations.shipTo_country = true;
                    if (!this.paymentInstrumentBillToValidate.valid) throw 'Favor de llenar los campos';
                }
                if (!this.paymentInstrument.id) {
                    let newPaymentInstrument = await this.paymentInstrument.save();
                    // ? Se debe de pushear en los customers y en customer no ?
                    // ? creo que no, solamente en uno
                    if (newPaymentInstrument.is_default) {
                        this.customer.paymentInstruments.forEach((x) => (x.is_default = false));
                        this.customers.find((x) => x.id == this.customer.id).paymentInstruments.forEach((x) => (x.is_default = false));
                    }

                    // this.customer.paymentInstruments.push(newPaymentInstrument);
                    this.customers.find((x) => x.id == this.customer.id).paymentInstruments.push(newPaymentInstrument);
                    this.paymentInstrument.id = newPaymentInstrument.id;
                    this.$toast.add(
                        new Toast({
                            summary: 'Creacion',
                            detail: 'Informacion de forma de pago guardada con exito',
                        })
                    );
                }
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async saveCustomer() {
            try {
                this.loading = true;
                //* Datos generales del cliente
                this.customer.buyerInformation_email = this.paymentParameters.orderInformationBillTo.email;
                //! Se agrega nuevo campo obligatorio
                //this.customer.name = this.paymentParameters.orderInformationBillTo.firstName + " " + this.paymentParameters.orderInformationBillTo.lastName;
                this.customer.name = this.paymentParameters.orderInformationBillTo.name;
                this.customer.phone = this.paymentParameters.orderInformationBillTo.phoneNumber;

                //* Validamos customer
                this.customerValidate = validate(this.customer, this.customerRules);
                if (!this.customerValidate.valid) throw 'Favor de validar los campos de cliente';

                //* Rellenamos los datos de facturacion
                // this.shippingAddress.id_bw_cybersource_customer = this.customer.id;
                this.shippingAddress.is_default = this.customer.id == null ? true : this.paymentParameters.orderInformationBillTo.is_default;
                this.shippingAddress.shipTo_firstName = this.paymentParameters.orderInformationBillTo.firstName;
                this.shippingAddress.shipTo_email = this.paymentParameters.orderInformationBillTo.email;
                this.shippingAddress.shipTo_phoneNumber = this.paymentParameters.orderInformationBillTo.phoneNumber;
                this.shippingAddress.shipTo_lastName = this.paymentParameters.orderInformationBillTo.lastName;
                this.shippingAddress.shipTo_address1 = this.paymentParameters.orderInformationBillTo.address1;
                this.shippingAddress.shipTo_address2 = this.paymentParameters.orderInformationBillTo.address2;
                this.shippingAddress.shipTo_locality = this.paymentParameters.orderInformationBillTo.locality;
                this.shippingAddress.shipTo_postalCode = this.paymentParameters.orderInformationBillTo.postalCode;
                this.shippingAddress.shipTo_administrativeArea = this.paymentParameters.orderInformationBillTo.district;
                this.shippingAddress.shipTo_country = this.paymentParameters.orderInformationBillTo.country ? this.paymentParameters.orderInformationBillTo.country.code : null;
                //* Validamos shipping
                this.shippingValidate = validate(this.shippingAddress, this.shippingRules);
                if (!this.shippingValidate.valid) throw 'Favor de validar los campos de direccion';

                if (!this.customer.id) {
                    //* Creamos el customer
                    let customer = await this.customer.save();
                    this.customer.id = customer.id;
                    this.customer.clientReferenceInformation_code = customer.clientReferenceInformation_code;
                    this.customers.push(customer);
                    //* Nuevo shipping addresS
                    this.shippingAddress.id_bw_cybersource_customer = customer.id;
                    let newShippingAddress = await this.shippingAddress.save();
                    this.customers.find((x) => x.id == newShippingAddress.id_bw_cybersource_customer).shippings.push(newShippingAddress);
                    this.shippingAddress.id = newShippingAddress.id;
                    this.$toast.add(
                        new Toast({
                            summary: 'Creacion',
                            detail: 'Informacion de cliente guardada con exito',
                        })
                    );
                } else {
                    //* Actualizamos el cliente
                    let customer = await this.customer.update();
                    let indexCustomer = this.customers.findIndex((x) => x.id == customer.id);
                    this.customers[indexCustomer].name = customer.name;
                    this.customers[indexCustomer].phone = customer.phone;
                    this.customers[indexCustomer].buyerInformation_email = customer.buyerInformation_email;

                    this.$toast.add(
                        new Toast({
                            summary: 'Modificacion',
                            detail: 'Informacion de cliente modificada con exito',
                        })
                    );
                    this.shippingAddress.id_bw_cybersource_customer = this.customer.id;
                    if (this.shippingAddress.id) {
                        //* Actualizamos la direccion de entrega
                        let shippingAddress = await this.shippingAddress.update();
                        let index = this.customers.find((x) => x.id == shippingAddress.id_bw_cybersource_customer).shippings.findIndex((y) => y.id == shippingAddress.id);
                        if (shippingAddress.is_default) this.customers.find((x) => x.id == shippingAddress.id_bw_cybersource_customer).shippings.forEach((x) => (x.is_default = false));
                        this.shippingAddress.id = shippingAddress.id;
                        this.customers.find((x) => x.id == shippingAddress.id_bw_cybersource_customer).shippings[index] = shippingAddress;
                        this.$toast.add(
                            new Toast({
                                summary: 'Modificacion',
                                detail: 'Informacion de direccion modificada con exito',
                            })
                        );
                    } else {
                        //* Creamos una nueva
                        let newShippingAddress = await this.shippingAddress.save();

                        if (newShippingAddress.is_default) this.customers.find((x) => x.id == newShippingAddress.id_bw_cybersource_customer).shippings.forEach((x) => (x.is_default = false));

                        this.shippingAddress.id = newShippingAddress.id;
                        //* Agregamos el nuevo shipping address a las direcciones
                        this.customers.find((x) => x.id == newShippingAddress.id_bw_cybersource_customer).shippings.push(newShippingAddress);
                        this.$toast.add(
                            new Toast({
                                summary: 'Creacion',
                                detail: 'Informacion de direccion guardada con exito',
                            })
                        );
                    }
                }
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async save() {
            try {
                this.loading = true;
                if (!this.paymentParameters.concept || this.paymentParameters.concept == '') throw 'Favor de agregar un concepto';
                if (!this.paymentParameters.orderInformationAmountDetails.totalAmount || this.paymentParameters.orderInformationAmountDetails.totalAmount == 0 || this.paymentParameters.orderInformationAmountDetails.totalAmount == '') {
                    throw 'Favor de agregar un importe valido';
                }
                if (this.paymentParameters.id && this.paymentParameters.id > 0) throw 'Esta transaccion ya fue realizada, favor de realizar una nueva';
                if (!this.customer.id) throw "No se encontro el token para 'cliente', favor de guardar o seleccionar un registro";
                if (!this.shippingAddress.id) throw "No se encontro el token para 'direccion de envio', favor de guardar o seleccionar un registro";
                if (!this.paymentInstrument.id) throw "No se encontro el token para 'instrumento de pago', favor de guardar o seleccionar un registro";
                // * Tokenization
                this.paymentParameters.paymentInformation.paymentInstrument = {
                    id: this.paymentInstrument.id,
                };
                this.paymentParameters.paymentInformation.customer = {
                    id: this.customer.id,
                };
                this.paymentParameters.paymentInformation.shippingAddress = {
                    id: this.shippingAddress.id,
                };

                let payload = {
                    customer_id: this.customer.id,
                    shipping_address_id: this.shippingAddress.id,
                    payment_instrument_id: this.paymentInstrument.id,
                    amount: this.paymentParameters.orderInformationAmountDetails.totalAmount.toString(),
                    currency: this.paymentParameters.orderInformationAmountDetails.currency,
                    capture: this.paymentParameters.capture.toString(),
                    concept: this.paymentParameters.concept,
                };

                if (this.paymentParameters.type == 3) {
                    payload.partialAmount = this.partialAmount.toString();
                    payload.totalCount = this.paymentParameters.installmentInformation.totalCount;
                }
                let response = await this.paymentParameters.processToken(this.paymentParameters.type, payload);

                if (response.status == 'DECLINED' || response.status == 'INVALID_REQUEST') {
                    let message = PAYMENT_DICTIONARY['201'].find((x) => x.status == response.status && x.reason == response.errorInformation.reason);
                    this.$toast.add({
                        severity: response.status == 'INVALID_REQUEST' ? 'error' : 'warn',
                        closable: true,
                        summary: response.status,
                        detail: message ? message.message_es : 'No se encontró el mensaje en el diccionario ' + response.errorInformation.reason,
                        life: 30000,
                    });
                } else {
                    let message = PAYMENT_DICTIONARY['201'].find((x) => x.status == response.status);
                    this.paymentParameters.id = response.id;
                    this.$toast.add({
                        severity: 'success',
                        closable: true,
                        summary: response.status,
                        detail: message ? message.message_es : 'No se encontró el mensaje en el diccionario',
                        life: 30000,
                    });
                    await this.paymentParameters.send(this.paymentParameters.id);
                    this.$toast.add({
                        severity: 'success',
                        closable: true,
                        summary: 'Email',
                        detail: 'Se ha enviado un correo al cliente con la informacion de la transaccion',
                        life: 3000,
                    });
                }
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        edit(entity) {
            this.entity = fillObject(this.entity, entity);
            this.newDialog = true;
        },
        confirmDelete(entity) {
            this.entity = fillObject(this.entity, entity);
            this.deleteDialog = true;
        },
        async sendEmail() {
            try {
                this.loading = true;
                if (this.entity.is_closed) {
                    throw 'No puede enviar un correo de un ticket cerrado';
                }
                await this.entity.enviarCorreoNuevo();
                this.$toast.add({
                    severity: 'success',
                    summary: 'Correo',
                    detail: 'Se envio el correo del ticket con exito',
                    life: 3000,
                });
                this.$emit('deleted');
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async deletePaymentInstrument() {
            try {
                this.loading = true;
                //* Eliminamos de la base
                await this.paymentInstrument.delete();
                //* Eliminamos de la vista
                this.customer.paymentInstruments.filter((x) => x.id != this.paymentInstrument.id);
                this.customers.find((x) => x.id == this.paymentInstrument.id_bw_cybersource_customer).paymentInstruments = this.customers
                    .find((x) => x.id == this.paymentInstrument.id_bw_cybersource_customer)
                    .paymentInstruments.filter((x) => x.id != this.paymentInstrument.id);
                this.paymentParameters.paymentInformation.type = null;
                this.newPaymentInstrument();
                this.$toast.add({
                    severity: 'success',
                    summary: 'Eliminacion',
                    detail: 'Registro eliminado con exito',
                    life: 3000,
                });
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async deleteShipping() {
            try {
                this.loading = true;
                //* Eliminamos de la base
                await this.shippingAddress.delete();
                //* Eliminamos de la vista
                this.customers.find((x) => x.id == this.shippingAddress.id_bw_cybersource_customer).shippings = this.customers
                    .find((x) => x.id == this.shippingAddress.id_bw_cybersource_customer)
                    .shippings.filter((x) => x.id != this.shippingAddress.id);
                this.paymentParameters.orderInformationBillTo.firstName = null;
                this.paymentParameters.orderInformationBillTo.lastName = null;
                this.paymentParameters.orderInformationBillTo.address1 = null;
                this.paymentParameters.orderInformationBillTo.address2 = null;
                this.paymentParameters.orderInformationBillTo.postalCode = null;
                this.paymentParameters.orderInformationBillTo.district = null;
                this.paymentParameters.orderInformationBillTo.locality = null;
                this.paymentParameters.orderInformationBillTo.country = null;

                this.$toast.add({
                    severity: 'success',
                    summary: 'Eliminacion',
                    detail: 'Registro eliminado con exito',
                    life: 3000,
                });
                this.$emit('deleted');
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async deleteEntity() {
            try {
                this.loading = true;
                //* Eliminamos de la base
                await this.entity.delete();
                //* Eliminamos de la vista
                this.entities = this.entities.filter((val) => val.id !== this.entity.id);

                this.$toast.add({
                    severity: 'success',
                    summary: 'Eliminacion',
                    detail: 'Registro eliminado con exito',
                    life: 3000,
                });
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        selectCustomer(customer) {
            this.customer = fillObject(this.customer, customer);
            let shipping = this.customer.shippings.find((x) => x.is_default);
            if (shipping) this.selectShipping(shipping);
            else this.shippingAddress = new BWShippingAddress(this.session);
            //* TRATAMOS DE LLENAR EL TIPO DE PAGO PREDETERMINADO
            let paymentInstrument = this.customer.paymentInstruments.find((x) => x.is_default);
            if (paymentInstrument) this.selectPaymentInstrument(paymentInstrument);
            else {
                this.paymentParameters.paymentInformation.card = {
                    number: null,
                    expirationMonth: null,
                    expirationYear: null,
                    type: null,
                    useAs: 'CREDIT',
                    securityCode: null,
                };
            }
        },
        selectShipping(shipping) {
            this.shippingAddress = fillObject(this.shippingAddress, shipping);
            let customer = this.customers.find((x) => x.id == shipping.id_bw_cybersource_customer);
            this.customer = fillObject(this.customer, customer);
            this.paymentParameters.orderInformationBillTo.name = this.customer.name;
            this.paymentParameters.orderInformationBillTo.email = this.customer.buyerInformation_email;
            this.paymentParameters.orderInformationBillTo.phoneNumber = this.customer.phone;
            this.paymentParameters.orderInformationBillTo.firstName = shipping.shipTo_firstName;
            this.paymentParameters.orderInformationBillTo.lastName = shipping.shipTo_lastName;
            this.paymentParameters.orderInformationBillTo.address1 = shipping.shipTo_address1;
            this.paymentParameters.orderInformationBillTo.address2 = shipping.shipTo_address2;
            this.paymentParameters.orderInformationBillTo.postalCode = shipping.shipTo_postalCode;
            this.paymentParameters.orderInformationBillTo.district = shipping.shipTo_administrativeArea;
            this.paymentParameters.orderInformationBillTo.locality = shipping.shipTo_locality;
            //* Tiene que ser el objecto
            this.paymentParameters.orderInformationBillTo.is_default = shipping.is_default;
            this.paymentParameters.orderInformationBillTo.country = this.countries.find((country) => country.code == shipping.shipTo_country);
            // //? Instrumento de pago predeterminado, este codigo tiene un bug pero no se por que
            // let paymentInstrument = this.customer.paymentInstruments.find(x => x.is_default);
            // if(paymentInstrument)
            //   this.selectShipping(paymentInstrument);
              },
        selectPaymentInstrument(paymentInstrument) {
            this.paymentInstrument = fillObject(this.paymentInstrument, paymentInstrument);
            this.paymentParameters.paymentInformation.paymentType.is_using_shipping = false;
            this.paymentParameters.paymentInformation.paymentType.is_default = this.paymentInstrument.is_default;
            this.paymentParameters.paymentInformation.card.number = this.paymentInstrument.card_number;
            this.paymentParameters.paymentInformation.card.securityCode = this.paymentInstrument.card_cvv;
            this.paymentParameters.paymentInformation.card.type = this.cards.find((x) => x.id == this.paymentInstrument.card_type_id);
            this.paymentParameters.paymentInformation.paymentType.type = this.paymentInstrument.type;
            this.paymentParameters.paymentInformation.card.useAs = this.paymentInstrument.useAs ?? 'credit';
            let date = moment(this.paymentInstrument.card_expirationMonth + '/01/' + this.paymentInstrument.card_expirationYear);
            this.paymentParameters.paymentInformation.card.expiration = date.toDate();
            this.paymentParameters.paymentInformation.card.expirationYear = this.paymentInstrument.card_expirationYear;
            this.paymentParameters.paymentInformation.card.expirationMonth = this.paymentInstrument.card_expirationMonth;
      
      },
        newPayment() {
            this.paymentParameters = new PaymentParameters(this.session);
            this.customer = new BWCustomer(this.session);
            this.shippingAddress = new BWShippingAddress(this.session);
            this.paymentInstrument = new BWPaymentInstrument(this.session);
        },
        newCustomer() {
            this.customer = new BWCustomer(this.session);
            this.shippingAddress = new BWShippingAddress(this.session);
            this.paymentInstrument = new BWPaymentInstrument(this.session);
            this.paymentParameters.orderInformationBillTo.name = null;
            this.paymentParameters.orderInformationBillTo.email = null;
            this.paymentParameters.orderInformationBillTo.phoneNumber = null;
            this.paymentParameters.orderInformationBillTo.firstName = null;
            this.paymentParameters.orderInformationBillTo.lastName = null;
            this.paymentParameters.orderInformationBillTo.address1 = null;
            this.paymentParameters.orderInformationBillTo.address2 = null;
            this.paymentParameters.orderInformationBillTo.postalCode = null;
            this.paymentParameters.orderInformationBillTo.district = null;
            this.paymentParameters.orderInformationBillTo.locality = null;
            this.paymentParameters.orderInformationBillTo.country = null;
        },
        newShipping() {
            this.shippingAddress = new BWShippingAddress(this.session);
            this.paymentInstrument = new BWPaymentInstrument(this.session);
            this.paymentParameters.orderInformationBillTo.address1 = null;
            this.paymentParameters.orderInformationBillTo.address2 = null;
            this.paymentParameters.orderInformationBillTo.postalCode = null;
            this.paymentParameters.orderInformationBillTo.district = null;
            this.paymentParameters.orderInformationBillTo.locality = null;
            this.paymentParameters.orderInformationBillTo.country = null;
        },
        newPaymentInstrument() {
            this.paymentInstrument = new BWPaymentInstrument(this.session);
            this.paymentParameters.paymentInformation.paymentType.type = null;
            this.paymentParameters.paymentInformation.card.expiration = null;
            this.paymentParameters.paymentInformation.card.expirationMonth = null;
            this.paymentParameters.paymentInformation.card.expirationYear = null;
            this.paymentParameters.paymentInformation.card.type = null;
            this.paymentParameters.paymentInformation.card.number = null;
            this.paymentParameters.paymentInformation.card.securityCode = null;
            this.paymentParameters.paymentInformation.paymentType.name = null;
            this.paymentParameters.paymentInformation.paymentType.is_using_shipping = true;
            this.paymentParameters.paymentInformation.paymentType.is_default = false;
            this.paymentParameters.paymentInformation.card.useAs = 'credit';
        },
        exportCSV() {
            this.$refs.dt.exportCSV();
        },
        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
        async refresh() {
            this.loading = true;
            try {
                this.paymentParameters = new PaymentParameters(this.session);
                this.customer = new BWCustomer(this.session);
                this.shippingAddress = new BWShippingAddress(this.session);
                this.paymentInstrument = new BWPaymentInstrument(this.session);
                this.customers = await this.customer.all();
                this.installment_config = await new BWCybersourceInstallmentConfiguration(this.session).retrieveByBranch();
                this.customers.forEach((customer) => {
                    customer.paymentInstruments.forEach((paymentInstrument) => {
                        paymentInstrument.type = paymentInstrument.useAs ?? 'credit';
                    });
                });

                //  const params = new Proxy(new URLSearchParams(window.location.search), {
                // get: (searchParams, prop) => searchParams.get(prop),
              
          

                // if (params) {
                //     this.ticket = (await axios.get('/api/businesswallet/aplicaciones/terminalvirtual/' + params.id)).data;
                if (this.session.customer) {
                    this.customer = new BWCustomer(this.session);
                    this.customer.id = this.session.customer;
                    let customerDb = await this.customer.retrieve();
                    this.customer = fillObject(this.customer, customerDb);
                    this.customer.shippings = customerDb.shippings;
                    let default_shipping = this.customer.shippings.find(x => x.is_default);
                    if (default_shipping){
                        this.selectShipping(default_shipping);   
                    }   

                      this.customer.paymentInstruments = customerDb.paymentInstruments;
                    let default_paymentInstruments = this.customer.paymentInstruments.find(x => x.is_default);
                    if (default_paymentInstruments){
                        this.selectPaymentInstrument(default_paymentInstruments);  
                    }          
                }

         
                
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.table-header {
    display: flex;
    justify-content: space-between;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 960px) {
    ::v-deep(.p-toolbar) {
        flex-wrap: wrap;

        .p-button {
            margin-bottom: 0.25rem;
        }
    }
}

.ddl-card-icon {
    width: 30px;
    margin-right: 5px;
}
</style>
